<template>
  <div class="HSK">
    <div class="banner">
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3>Thesis Proofreading <span>Service</span></h3>
        </div>
        <p class="_jieshao">
          Academic papers pay great attention to logical thinking, requiring clear and reasonable logical relations between sentences, paragraphs and chapters, and the whole article should also have a very obvious logical thinking, so as to effectively express the academic thought we want to express. In addition, the details of vocabulary, grammar and literature citation will also affect whether the paper can get high marks. In order to help you write high-quality papers, students can teach you how to write a high-score Chinese paper from word syntax, logical thinking and article structure.
        </p>
        <div class="imgbox"
             style="margin:20px 0;">
          <img src="@/assets/jiaodui001.png"
               alt="">
        </div>
        <div class="_detail">
          <div class="text">
            <p class="_jieshao">
              Proofreaders' proofreading services provide more than basic checks of spelling, grammar and punctuation. We are experienced in providing proofreading services for international students whose mother tongue is not Chinese.
            </p>
            <p class="_jieshao">
              Our proofreading team understands the students' views and will proofread your articles or papers to ensure their academic reading and help you avoid losing points because your Chinese is not perfect. Our proofreaders will also provide comments. For example, they may emphasize areas that need to be improved, such as clearer.
            </p>
            <p class="txt _t">
              In summary, our proofreading services provide:
            </p>
            <p class="txt"><b>·</b> correct grammatical errors</p>
            <p class="txt"><b>·</b> correction of punctuation errors</p>
            <p class="txt"><b>·</b> correct spelling mistakes</p>
            <p class="txt"><b>·</b> correction of printing errors</p>
            <p class="_jieshao">
              Style change – for example, replace basic words with more formal alternatives where appropriate
            </p>
            <p class="_jieshao">
              For example, when a sentence is not clear enough, the comment box will indicate.
            </p>
            <p class="txt _t">
              Proofreading service scheme
            </p>
            <div class="_table">
              <div class="_row _head">
                <div class="_col w100">Different service schemes meet your various needs</div>
              </div>
              <div class="_row _h-z3">
                <div class="_col"> <b class="_tt"> Good Quality</b></div>
                <div class="_col"> <b class="_tt"> Whole Process</b></div>
                <div class="_col"> <b class="_tt"> Scientific Process</b></div>
              </div>
              <div class="_row _h-z3">
                <div class="_col">
                  <div class="_left stips">
                    <!-- <p>Check the language grammar word by word and sentence by sentence</p>
                    <p>Review sentence logic</p>
                    <p>Double round editing and revision</p> -->
                    <div class="imgbox">
                      <img src="@/assets/A001.png"
                           alt="">
                    </div>
                  </div>
                </div>
                <div class="_col">
                  <div class="_left stips">
                    <!-- <p>Check the language grammar word by word and sentence by sentence</p>
                    <p>Review sentence logic</p>
                    <p>Double round editing and revision</p>
                    <p>The whole process is free to publish</p> -->
                    <div class="imgbox">
                      <img src="@/assets/A002.png"
                           alt="">
                    </div>
                  </div>
                </div>
                <div class="_col">
                  <div class="_left stips">
                    <!-- <p>Check the language grammar word by word and sentence by sentence</p>
                    <p>Review sentence logic</p>
                    <p>Double round editing and revision</p>
                    <p>The whole process is free to publish</p>
                    <p>Detailed guidance suggestions from the perspective of "reviewer"</p> -->
                    <div class="imgbox">
                      <img src="@/assets/A003.png"
                           alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="_row _h-z3">
                <div class="_col">
                  <el-button style="margin:20px 0"
                             @click="onShowDilog(1)">Plan and time selection</el-button>
                </div>
                <div class="_col">
                  <el-button style="margin:20px 0"
                             @click="onShowDilog(2)">Plan and time selection</el-button>
                </div>
                <div class="_col">
                  <el-button style="margin:20px 0"
                             @click="onShowDilog(3)">Plan and time selection</el-button>
                </div>
              </div>
              <div class="_row _h-z3">
                <div class="_col">
                  <el-button style="margin:20px 0"
                             @click="onShowForm('Good Quality')">Choose the scheme</el-button>
                </div>
                <div class="_col">
                  <el-button style="margin:20px 0"
                             @click="onShowForm('Whole Process')">Choose the scheme</el-button>
                </div>
                <div class="_col">
                  <el-button style="margin:20px 0"
                             @click="onShowForm('Scientific Process')">Choose the scheme</el-button>
                </div>
              </div>
            </div>

            <div class="tit">Our advantages</div>
            <p class="txt _t">Information confidentiality</p>

            <p class="txt">An exclusive contract has been signed. Only the editor in charge of your file has permission to see the contents of the file.</p>
            <p class="txt _t">Reliable quality</p>
            <p class="txt">Excellent professional team to ensure high-quality service output, multi round interaction to ensure that the service runs on demand.</p>
            <p class="txt _t">Privacy protection</p>
            <p class="txt">By signing a confidentiality agreement, your personal information will be strictly protected and will not be disclosed to third parties.</p>
            <p class="txt _t">Punctual service</p>
            <p class="txt">Submit the manuscript in strict accordance with the date and time determined during work confirmation.</p>
            <p class="txt _t">Real time communication</p>
            <p class="txt">Real time communication progress, timely feedback, perfect service to every customer.</p>

          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogVisible"
               title=""
               width="80%">
      <div v-if="this.tableIndex == 1">
        <div class="_table bor">
          <div class="_row _head">
            <div class="_col w100">Good Quality</div>
          </div>
          <div class="_row">
            <div class="_col w16"></div>
            <div class="_col w16">6 working days</div>
            <div class="_col w16">4 working days</div>
            <div class="_col w16">3 working days</div>
            <div class="_col w16">2 working days</div>
            <div class="_col w16">
              <div class="_left">
                <p>隔日返稿（24h）</p>
                <p>当天20：30钱提交订单</p>
              </div>
            </div>
          </div>
          <div class="_row">
            <div class="_col w16">1-1500 words</div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16">¥0.60/word</div>
            <div class="_col w16">¥0.90/word</div>
          </div>
          <div class="_row">
            <div class="_col w16">1501-4000 words</div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16">¥0.60/word</div>
            <div class="_col w16">¥0.70/word</div>
            <div class="_col w16">¥0.90/word</div>
          </div>
          <div class="_row">
            <div class="_col w16">4001-7000 words</div>
            <div class="_col w16"></div>
            <div class="_col w16">¥0.60/word</div>
            <div class="_col w16">¥0.70/word</div>
            <div class="_col w16">¥0.80/word</div>
            <div class="_col w16">¥1.00/word</div>
          </div>
          <div class="_row">
            <div class="_col w16">7001-10000 words</div>
            <div class="_col w16">¥0.48/word</div>
            <div class="_col w16">¥0.56/word</div>
            <div class="_col w16">¥0.64/word</div>
            <div class="_col w16">¥0.72/word</div>
            <div class="_col w16"></div>
          </div>
          <div class="_row">
            <div class="_col w16">≧10000 words</div>
            <div class="_col w16">¥0.45/word</div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
          </div>
          <div class="_row">
            <div class="_col w100">
              <div class="_left">
                <p>
                  *Holidays and weekends, five working days a week.
                </p>
                <p>
                  *There may be time difference at home and abroad, mainly Beijing time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="this.tableIndex == 2">
        <div class="_table bor">
          <div class="_row _head">
            <div class="_col w100">Whole Process</div>
          </div>
          <div class="_row">
            <div class="_col w16"></div>
            <div class="_col w16">6 working days</div>
            <div class="_col w16">4 working days</div>
            <div class="_col w16">3 working days</div>
            <div class="_col w16">2 working days</div>
            <div class="_col w16">
              <div class="_left">
                <p>隔日返稿（24h）</p>
                <p>当天20：30钱提交订单</p>
              </div>
            </div>
          </div>
          <div class="_row">
            <div class="_col w16">1-1500 words</div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16">¥1.20/word</div>
            <div class="_col w16">¥1.80/word</div>
          </div>
          <div class="_row">
            <div class="_col w16">1501-4000 words</div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16">¥1.20/word</div>
            <div class="_col w16">¥1.40/word</div>
            <div class="_col w16">¥1.80/word</div>
          </div>
          <div class="_row">
            <div class="_col w16">4001-7000 words</div>
            <div class="_col w16"></div>
            <div class="_col w16">¥1.20/word</div>
            <div class="_col w16">¥1.40/word</div>
            <div class="_col w16">¥1.60/word</div>
            <div class="_col w16">¥2.00/word</div>
          </div>
          <div class="_row">
            <div class="_col w16">7001-10000 words</div>
            <div class="_col w16">¥0.96/word</div>
            <div class="_col w16">¥1.12/word</div>
            <div class="_col w16">¥1.28/word</div>
            <div class="_col w16">¥1.44/word</div>
            <div class="_col w16"></div>
          </div>
          <div class="_row">
            <div class="_col w16">≧10000 words</div>
            <div class="_col w16">¥0.90/word</div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
            <div class="_col w16"></div>
          </div>
          <div class="_row">
            <div class="_col w100">
              <div class="_left">
                <p>
                  *Holidays and weekends, five working days a week.
                </p>
                <p>
                  *There may be time difference at home and abroad, mainly Beijing time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="this.tableIndex == 3">
        <div class="_table bor">
          <div class="_row _head">
            <div class="_col w100">Whole Process</div>
          </div>
          <div class="_row">
            <div class="_col w25"></div>
            <div class="_col w25">6 working days</div>
            <div class="_col w25">4 working days</div>
            <div class="_col w25">2 working days</div>

          </div>
          <div class="_row">
            <div class="_col w25">1-1500 words</div>
            <div class="_col w25"></div>
            <div class="_col w25"></div>
            <div class="_col w25">¥1.62/word</div>
          </div>
          <div class="_row">
            <div class="_col w25">1501-4000 words</div>
            <div class="_col w25"></div>
            <div class="_col w25"></div>
            <div class="_col w25">¥1.53/word</div>
          </div>
          <div class="_row">
            <div class="_col w25">4001-7000 words</div>
            <div class="_col w25"></div>
            <div class="_col w25">¥1.44/word</div>
            <div class="_col w25"></div>
          </div>
          <div class="_row">
            <div class="_col w25">7001-10000 words</div>
            <div class="_col w25">¥1.35/word</div>
            <div class="_col w25"></div>
            <div class="_col w25"></div>
          </div>
          <div class="_row">
            <div class="_col w25">≧10000 words</div>
            <div class="_col w25">¥1.26/word</div>
            <div class="_col w25"></div>
            <div class="_col w25"></div>
          </div>
          <div class="_row">
            <div class="_col w100">
              <div class="_left">
                <p>
                  *Holidays and weekends, five working days a week.
                </p>
                <p>
                  *There may be time difference at home and abroad, mainly Beijing time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="ShowForm"
               title=""
               width="50%">
      <div>

        <h3 class="formHead">{{formHead}}</h3>
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="100px">
          <el-form-item label="Your name"
                        prop="cert_name">
            <el-input v-model="form.cert_name"></el-input>
          </el-form-item>
          <el-form-item label="Phone"
                        prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="E-mail"
                        prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <div style="margin-bottom:20px">
            Note: Please leave your message, our consultant will contact you soon ASAP. <br>
            * Working Hours (Beijing time 9:00am-5:30pm Monday-Friday, except national holidays)
          </div>
          <el-button type="primary"
                     @click="onSubmit('form')">Submit</el-button>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ShowForm = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ElMessage } from 'element-plus'

export default {
  data () {
    return {
      activeName: 'first',
      dialogVisible: false,
      ShowForm: false,
      tableIndex: 1,
      formHead: '',
      form: {
        cert_name: '',
        phone: '',
        email: '',
      },
      rules: {
        cert_name: [
          {
            required: true,
            message: 'Please input Id name',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 64,
            message: 'Length should be 3 to 64',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input your email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input your phone',
            trigger: 'blur',
          },
        ],
      }
    }
  },
  methods: {
    onShowDilog (index) {
      this.tableIndex = index
      this.dialogVisible = true
    },
    onShowForm (val) {
      this.formHead = val
      this.ShowForm = true
    },
    isCellPhone (val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    onSubmit (formName) {
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            form_type: 4,
            content: this.formHead,
            ...this.form
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.ShowForm = false
              this.$refs[formName].resetFields()

            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 400px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
._jieshao {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
}
._detail {
  .el-tabs ::v-deep {
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .text {
    .txt {
      text-indent: 2em;
      font-size: 15px;
      margin-bottom: 15px;
      color: #363636;
      &._t {
        font-weight: bold;
        text-indent: 0;
      }
    }
  }
  ._table {
    ._row {
      color: #333;

      ._col {
        background-image: linear-gradient(to right, #f1f1f1, #bfbfbf);
      }
      //       ._col:nth-child(1) {
      //   background-color: #9e9ed3;
      // }
      // ._col:nth-child(2) {
      //   background-color: #55bec9;
      // }
      // ._col:nth-child(3) {
      //   background-color: #4d86cb;
      // }
      &._head {
        ._col {
          font-size: 20px;
          font-weight: bold;
          background-image: none;
          background-color: #0c3879;
          color: #fff;
        }
      }
    }
  }
}
._table ::v-deep {
  .el-button {
    background-color: #0c3879;
    border-color: #fff;
    color: #fff;
  }
}
._table {
  margin: 0 auto;
  margin-top: 20px;
  &.bor {
    ._row {
      border: 1px solid #dedede;
      ._col {
        border-right: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
      }
    }
  }
  ._row {
    // border: 1px solid #dedede;
    margin-bottom: -1px;
    overflow: hidden;
    display: flex;
    font-size: 14px;
    &._head {
      background-color: #f1f1f1;
    }
    ._col {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 40px;
      // border-right: 1px solid #dedede;
      // border-bottom: 1px solid #dedede;
      text-align: center;
      margin-bottom: -1px;
      &:last-child {
        border-right: none;
      }
      &.w100 {
        width: 100%;
      }
      &.w80 {
        width: 80%;
      }

      &.w75 {
        width: 75%;
      }
      &.w50 {
        width: 50%;
      }
      &.w25 {
        width: 25%;
      }
      &.w40 {
        width: 40%;
      }
      &.w20 {
        width: 20%;
      }
      &.w16 {
        width: 16.6666666%;
      }
      ._left {
        padding: 10px;
        width: 100%;
        height: 100%;
        text-align: left;
        &.stips {
          p::before {
            content: "·";
            font-weight: bold;
            margin-right: 5px;
          }
        }
        p {
          font-size: 16px;
          line-height: 1.7;
          margin-bottom: 10px;
          background-color: rgba($color: #ffffff, $alpha: 0.2);
          padding: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &._h-z3 {
      ._col {
        width: 33.333%;
      }
    }
  }
  .nei {
    width: 100%;
    ._colm {
      width: 100%;
      border-bottom: 1px solid #dedede;
      line-height: 40px;
      text-align: center;
      margin-bottom: -1px;
    }
  }
}

.formHead {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}
.el-form ::v-deep {
  .el-button {
    width: 100%;
  }
}
._tt {
  font-size: 18px;
}
</style>